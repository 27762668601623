<template>
   <section
      class="bg-[url('/images/footer/sponsor_bg.svg')] bg-cover bg-no-repeat bg-center"
   >
      <div
         class="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8"
      >
         <h2
            class="mb-8 lg:mb-16 text-3xl font-extrabold leading-tight text-center text-primary-gray md:text-4xl uppercase"
         >
            {{ $t('about.our_supporters') }}
         </h2>
         <div
            class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-8 items-center justify-items-center overflow-hidden"
         >
            <div
               class="bg-white h-full rounded-2xl"
               v-for="sponsor in $page.props.pageBlocks.sponsors.data"
               :key="sponsor.id"
            >
               <img
                  loading="lazy"
                  class="bg-white h-28 lg:h-36 object-center object-contain rounded-2xl px-4"
                  :srcset="sponsor.media.original_url"
                  :alt="sponsor.title"
               >
            </div>
         </div>
      </div>
   </section>
</template>

<script setup>

</script>

<style scoped>

</style>
